<template>
  <div>
    <!-- Root element -->
    <router-link :to="localizedRoute({ name: 'home', query: $route.query })" :title="$t('Home Page')" class="no-underline inline-flex">
      <template v-for="(logo, index) in logos">
        <div :class="getLogoClass(index)" @click="handleLogoClick(index)" :key="index">
          <img :src="getLogoPath(logo)" :width="logo.width" :height="logo.height" :alt="$t(defaultTitle)" class="logoimg">
        </div>
        <div v-if="showDividers && index < logos.length - 1" class="brdr-logo-divider my20 brdr-cl-accent brdr-right-1" :key="index" />
      </template>
    </router-link>
    <no-ssr>
      <partner-modal-sbcr
        v-if="displayPartnerPages() && partnerId === 'sbcr'"
        :visible="isPartnerModalVisible"
        :partner-id="partnerId"
        @closeModal="isPartnerModalVisible = false"
      />
    </no-ssr>
    <no-ssr>
      <partner-modal-highland-sport
        v-if="displayPartnerPages() && partnerId === 'highlandsport'"
        :visible="isPartnerModalVisible"
        :partner-id="partnerId"
        @closeModal="isPartnerModalVisible = false"
      />
    </no-ssr>
  </div>
</template>

<script>
import config from 'config'
import NoSSR from 'vue-no-ssr'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import PartnerModalSbcr from '../theme/sbcr/PartnerModalSbcr.vue';
import PartnerModalHighlandSport from '../theme/highlandsport/PartnerModalHighlandSport.vue';

export default {
  data() {
    const storeView = currentStoreView()
    return {
      defaultTitle: storeView.seo.defaultTitle ? storeView.seo.defaultTitle : config.seo.defaultTitle,
      windowWidth: 0,
      isPartnerModalVisible: false,
      partnerId: ''
    }
  },
  props: {
    logos: {
      type: Array,
      required: true,
      default: () => [{
        path: '/assets/anylogo.svg',
        pathResp: '/assets/anylogo.svg',
        width: 'auto',
        height: '66px'
      }]
    },
    showDividers: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getPartnerId () {
      return this.$ssrContext?.partnerData.id || config.partnerData.id;
    },
    handleLogoClick(index) {
      if (index === 0) {
        this.isPartnerModalVisible = !this.isPartnerModalVisible;
      }
    },
    displayPartnerPages () {
      return this.$ssrContext?.partnerData.displayPartnerPages || config.partnerData.displayPartnerPages;
    },
    getLogoClass(index) {
      if (this.logos.length > 1) {
        if (index === 0) {
          return 'noselect my15 pr15';
        } else if (index === this.logos.length - 1) {
          return 'noselect my15 pl15';
        } else {
          return 'noselect my15';
        }
      }
      return 'noselect';
    },
    getLogoPath(logo) {
      return this.windowWidth < 575 ? logo.pathResp : logo.path;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    getLogoPath(logo) {
      return this.windowWidth < 575 ? logo.pathResp : logo.path;
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
    this.partnerId = this.getPartnerId();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  components: {
    'no-ssr': NoSSR,
    PartnerModalSbcr,
    PartnerModalHighlandSport
  }
}
</script>
<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';

  $color-partner: color(partner);
  .brdr-cl-accent {
    border-color: $color-partner;
  }
  .logoimg {
    @media only screen and (max-width: 575px) {
      height:38px;
    }
    @media only screen and (max-width: 500px) {
      aspect-ratio: 1;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      height:48px;
    }
  }
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
</style>
